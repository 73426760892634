import * as Types from './types.generated';

import gql from 'graphql-tag';
import * as Urql from 'urql';
export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
export type BaseUserFieldsFragment = { __typename: 'User', id: string, username?: string | null, firstName?: string | null, lastName?: string | null, discordId?: string | null, telegramId?: string | null, twitterId?: string | null, steamId?: string | null, twitchId?: string | null, profilePictureUrl?: string | null, emailAddress: string, phoneNumber?: string | null, dateOfBirth?: string | null, timeZone?: string | null, createdAt?: any | null, requiredFieldsComplete?: boolean | null, xp: number, level: number, walletAddressSOL?: string | null, walletAddressETH?: string | null, walletAddressZK?: string | null, newsletterEmail?: string | null };

export type CurrentUserQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type CurrentUserQuery = { __typename: 'Query', currentUser: { __typename: 'User', id: string, username?: string | null, firstName?: string | null, lastName?: string | null, discordId?: string | null, telegramId?: string | null, twitterId?: string | null, steamId?: string | null, twitchId?: string | null, profilePictureUrl?: string | null, emailAddress: string, phoneNumber?: string | null, dateOfBirth?: string | null, timeZone?: string | null, createdAt?: any | null, requiredFieldsComplete?: boolean | null, xp: number, level: number, walletAddressSOL?: string | null, walletAddressETH?: string | null, walletAddressZK?: string | null, newsletterEmail?: string | null } };

export type UserLevelQueryVariables = Types.Exact<{
  xp: Types.Scalars['Float'];
}>;


export type UserLevelQuery = { __typename: 'Query', currentUserLevelData: { __typename: 'Level', level: number, min: number, max?: number | null } };

export type UpdateCurrentUserMutationVariables = Types.Exact<{
  input: Types.UpdateUserInput;
}>;


export type UpdateCurrentUserMutation = { __typename: 'Mutation', updateCurrentUser: { __typename: 'User', id: string, username?: string | null, firstName?: string | null, lastName?: string | null, discordId?: string | null, telegramId?: string | null, twitterId?: string | null, steamId?: string | null, twitchId?: string | null, profilePictureUrl?: string | null, emailAddress: string, phoneNumber?: string | null, dateOfBirth?: string | null, timeZone?: string | null, createdAt?: any | null, requiredFieldsComplete?: boolean | null, xp: number, level: number, walletAddressSOL?: string | null, walletAddressETH?: string | null, walletAddressZK?: string | null, newsletterEmail?: string | null } };

export type UpdatePasswordMutationVariables = Types.Exact<{
  currentPassword: Types.Scalars['String'];
  newPassword: Types.Scalars['String'];
}>;


export type UpdatePasswordMutation = { __typename: 'Mutation', updatePassword: { __typename: 'User', id: string, username?: string | null, firstName?: string | null, lastName?: string | null, discordId?: string | null, telegramId?: string | null, twitterId?: string | null, steamId?: string | null, twitchId?: string | null, profilePictureUrl?: string | null, emailAddress: string, phoneNumber?: string | null, dateOfBirth?: string | null, timeZone?: string | null, createdAt?: any | null, requiredFieldsComplete?: boolean | null, xp: number, level: number, walletAddressSOL?: string | null, walletAddressETH?: string | null, walletAddressZK?: string | null, newsletterEmail?: string | null } };

export type UpdateTwitterHandleMutationVariables = Types.Exact<{ [key: string]: never; }>;


export type UpdateTwitterHandleMutation = { __typename: 'Mutation', updateTwitterHandle: { __typename: 'SocialNameUpdateResult', success: boolean } };

export type UpdateDiscordUsernameMutationVariables = Types.Exact<{ [key: string]: never; }>;


export type UpdateDiscordUsernameMutation = { __typename: 'Mutation', updateDiscordUsername: { __typename: 'SocialNameUpdateResult', success: boolean } };

export type UpdateSteamUsernameMutationVariables = Types.Exact<{ [key: string]: never; }>;


export type UpdateSteamUsernameMutation = { __typename: 'Mutation', updateSteamUsername: { __typename: 'SocialNameUpdateResult', success: boolean } };

export type UpdateTwitchUsernameMutationVariables = Types.Exact<{ [key: string]: never; }>;


export type UpdateTwitchUsernameMutation = { __typename: 'Mutation', updateTwitchUsername: { __typename: 'SocialNameUpdateResult', success: boolean } };

export type RemoveTwitterHandleMutationVariables = Types.Exact<{ [key: string]: never; }>;


export type RemoveTwitterHandleMutation = { __typename: 'Mutation', removeTwitter: { __typename: 'SocialNameUpdateResult', success: boolean } };

export type RemoveDiscordUsernameMutationVariables = Types.Exact<{ [key: string]: never; }>;


export type RemoveDiscordUsernameMutation = { __typename: 'Mutation', removeDiscord: { __typename: 'SocialNameUpdateResult', success: boolean } };

export type RemoveSteamUsernameMutationVariables = Types.Exact<{ [key: string]: never; }>;


export type RemoveSteamUsernameMutation = { __typename: 'Mutation', removeSteam: { __typename: 'SocialNameUpdateResult', success: boolean } };

export type RemoveTwitchUsernameMutationVariables = Types.Exact<{ [key: string]: never; }>;


export type RemoveTwitchUsernameMutation = { __typename: 'Mutation', removeTwitch: { __typename: 'SocialNameUpdateResult', success: boolean } };

export type RemoveTelegramUsernameMutationVariables = Types.Exact<{ [key: string]: never; }>;


export type RemoveTelegramUsernameMutation = { __typename: 'Mutation', removeTelegram: { __typename: 'SocialNameUpdateResult', success: boolean } };

export const BaseUserFieldsFragmentDoc = gql`
    fragment BaseUserFields on User {
  id
  username
  firstName
  lastName
  discordId
  telegramId
  twitterId
  steamId
  twitchId
  profilePictureUrl
  emailAddress
  phoneNumber
  dateOfBirth
  timeZone
  createdAt
  requiredFieldsComplete
  xp
  level
  walletAddressSOL
  walletAddressETH
  walletAddressZK
  newsletterEmail
}
    `;
export const CurrentUserDocument = gql`
    query CurrentUser {
  currentUser {
    ...BaseUserFields
  }
}
    ${BaseUserFieldsFragmentDoc}`;

export function useCurrentUserQuery(options?: Omit<Urql.UseQueryArgs<CurrentUserQueryVariables>, 'query'>) {
  return Urql.useQuery<CurrentUserQuery>({ query: CurrentUserDocument, ...options });
};
export const UserLevelDocument = gql`
    query UserLevel($xp: Float!) {
  currentUserLevelData(xp: $xp) {
    level
    min
    max
  }
}
    `;

export function useUserLevelQuery(options: Omit<Urql.UseQueryArgs<UserLevelQueryVariables>, 'query'>) {
  return Urql.useQuery<UserLevelQuery>({ query: UserLevelDocument, ...options });
};
export const UpdateCurrentUserDocument = gql`
    mutation UpdateCurrentUser($input: UpdateUserInput!) {
  updateCurrentUser(input: $input) {
    ...BaseUserFields
  }
}
    ${BaseUserFieldsFragmentDoc}`;

export function useUpdateCurrentUserMutation() {
  return Urql.useMutation<UpdateCurrentUserMutation, UpdateCurrentUserMutationVariables>(UpdateCurrentUserDocument);
};
export const UpdatePasswordDocument = gql`
    mutation UpdatePassword($currentPassword: String!, $newPassword: String!) {
  updatePassword(currentPassword: $currentPassword, newPassword: $newPassword) {
    ...BaseUserFields
  }
}
    ${BaseUserFieldsFragmentDoc}`;

export function useUpdatePasswordMutation() {
  return Urql.useMutation<UpdatePasswordMutation, UpdatePasswordMutationVariables>(UpdatePasswordDocument);
};
export const UpdateTwitterHandleDocument = gql`
    mutation UpdateTwitterHandle {
  updateTwitterHandle {
    success
  }
}
    `;

export function useUpdateTwitterHandleMutation() {
  return Urql.useMutation<UpdateTwitterHandleMutation, UpdateTwitterHandleMutationVariables>(UpdateTwitterHandleDocument);
};
export const UpdateDiscordUsernameDocument = gql`
    mutation updateDiscordUsername {
  updateDiscordUsername {
    success
  }
}
    `;

export function useUpdateDiscordUsernameMutation() {
  return Urql.useMutation<UpdateDiscordUsernameMutation, UpdateDiscordUsernameMutationVariables>(UpdateDiscordUsernameDocument);
};
export const UpdateSteamUsernameDocument = gql`
    mutation updateSteamUsername {
  updateSteamUsername {
    success
  }
}
    `;

export function useUpdateSteamUsernameMutation() {
  return Urql.useMutation<UpdateSteamUsernameMutation, UpdateSteamUsernameMutationVariables>(UpdateSteamUsernameDocument);
};
export const UpdateTwitchUsernameDocument = gql`
    mutation updateTwitchUsername {
  updateTwitchUsername {
    success
  }
}
    `;

export function useUpdateTwitchUsernameMutation() {
  return Urql.useMutation<UpdateTwitchUsernameMutation, UpdateTwitchUsernameMutationVariables>(UpdateTwitchUsernameDocument);
};
export const RemoveTwitterHandleDocument = gql`
    mutation RemoveTwitterHandle {
  removeTwitter {
    success
  }
}
    `;

export function useRemoveTwitterHandleMutation() {
  return Urql.useMutation<RemoveTwitterHandleMutation, RemoveTwitterHandleMutationVariables>(RemoveTwitterHandleDocument);
};
export const RemoveDiscordUsernameDocument = gql`
    mutation RemoveDiscordUsername {
  removeDiscord {
    success
  }
}
    `;

export function useRemoveDiscordUsernameMutation() {
  return Urql.useMutation<RemoveDiscordUsernameMutation, RemoveDiscordUsernameMutationVariables>(RemoveDiscordUsernameDocument);
};
export const RemoveSteamUsernameDocument = gql`
    mutation RemoveSteamUsername {
  removeSteam {
    success
  }
}
    `;

export function useRemoveSteamUsernameMutation() {
  return Urql.useMutation<RemoveSteamUsernameMutation, RemoveSteamUsernameMutationVariables>(RemoveSteamUsernameDocument);
};
export const RemoveTwitchUsernameDocument = gql`
    mutation RemoveTwitchUsername {
  removeTwitch {
    success
  }
}
    `;

export function useRemoveTwitchUsernameMutation() {
  return Urql.useMutation<RemoveTwitchUsernameMutation, RemoveTwitchUsernameMutationVariables>(RemoveTwitchUsernameDocument);
};
export const RemoveTelegramUsernameDocument = gql`
    mutation RemoveTelegramUsername {
  removeTelegram {
    success
  }
}
    `;

export function useRemoveTelegramUsernameMutation() {
  return Urql.useMutation<RemoveTelegramUsernameMutation, RemoveTelegramUsernameMutationVariables>(RemoveTelegramUsernameDocument);
};