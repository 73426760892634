const SENTRY_DSN = process.env.NEXT_PUBLIC_SENTRY_DSN as string;
const SENTRY_ENVIRONMENT = process.env.ENVIRONMENT_NAME;

const config = {
  ignoreErrors: ['CombinedError: [GraphQL] Unauthorized'],

  dsn: SENTRY_DSN,
  // We recommend adjusting this value in production, or using tracesSampler
  // for finer control
  tracesSampleRate: 1.0,
  environment: SENTRY_ENVIRONMENT,

  // ...
  // Note: if you want to override the automatic release value, do not set a
  // `release` value here - use the environment variable `SENTRY_RELEASE`, so
  // that it will also get attached to your source maps
};

export default config;
