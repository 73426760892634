import { NextSeo, NextSeoProps } from 'next-seo';
import Head from 'next/head';
import { LastUpdated } from '../../features/academy/last-updated';

const TWITTER_HANDLE = '@BeamGamers';

interface SeoProps extends NextSeoProps {
  lastUpdate?: string;
  keywords?: string;
}

export const Seo: React.FC<SeoProps> = ({
  lastUpdate,
  keywords,
  title,
  description,
  canonical,
  nofollow,
  noindex,
  openGraph,
}) => (
  <>
    <NextSeo
      title={title}
      description={description}
      canonical={canonical}
      nofollow={nofollow}
      noindex={noindex}
      openGraph={openGraph}
      twitter={{
        handle: TWITTER_HANDLE,
        site: TWITTER_HANDLE,
        cardType: 'summary_large_image',
      }}
    />
    <Head>
      {lastUpdate && <LastUpdated lastUpdate={lastUpdate} />}
      {keywords && <meta name="keywords" content={keywords} />}
    </Head>
  </>
);
