export const enum ApplicationError {
  UNAUTHORIZED = 'Unauthorized', // Lowercased as it is returned from Auth0
  UNVERIFIED_EMAIL = 'UNVERIFIED_EMAIL',
  MISSING_REQUIRED_FIELDS = 'MISSING_REQUIRED_FIELDS',
  GAME_NOT_FOUND = 'GAME_NOT_FOUND',
  USER_NOT_FOUND = 'USER_NOT_FOUND',
  SCHOLARSHIP_NOT_FOUND = 'SCHOLARSHIP_NOT_FOUND',
  DUPLICATE_SCHOLARSHIP_FOR_GAME = 'DUPLICATE_SCHOLARSHIP_FOR_GAME',
  USERNAME_NOT_UNIQUE = 'USERNAME_NOT_UNIQUE',
  USERNAME_INVALID = 'USERNAME_INVALID',
  DISCORD_ID_INVALID = 'DISCORD_ID_INVALID',
  DATE_OF_BIRTH_INVALID = 'DATE_OF_BIRTH_INVALID',
  DATE_OF_BIRTH_MIN_AGE = 'DATE_OF_BIRTH_MIN_AGE',
  FIRST_NAME_INVALID = 'FIRST_NAME_INVALID',
  LAST_NAME_INVALID = 'LAST_NAME_INVALID',
  PHONE_NUMBER_INVALID = 'PHONE_NUMBER_INVALID',
  WALLET_NUMBER_INVALID = 'WALLET_NUMBER_INVALID',
  NEWSLETTER_NUMBER_INVALID = 'NEWSLETTER_NUMBER_INVALID',
  TIME_ZONE_INVALID = 'TIME_ZONE_INVALID',
  CURRENT_PASSWORD_REQUIRED = 'CURRENT_PASSWORD_REQUIRED',
  CURRENT_PASSWORD_INVALID = 'CURRENT_PASSWORD_INVALID',
  NEW_PASSWORD_REQUIRED = 'NEW_PASSWORD_REQUIRED',
  PASSWORD_REQUIREMENTS_NOT_MET = 'PASSWORD_REQUIREMENTS_NOT_MET',
  FILE_TYPE_NOT_ALLOWED = 'FILE_TYPE_NOT_ALLOWED',
  IMAGE_CORRUPTED = 'IMAGE_CORRUPTED',
  IMAGE_DIMENSIONS_TOO_SMALL = 'IMAGE_DIMENSIONS_TOO_SMALL',
  ETHEREUM_ADDRESS_INVALID = 'ETHEREUM_ADDRESS_INVALID',
  ONE_TIME_INSERT = 'ONE_TIME_INSERT',
  GIVEAWAY_NOT_FOUND = 'GIVEAWAY_NOT_FOUND',
  LEVEL_NOT_REACHED = 'LEVEL_NOT_REACHED',
  GIVEAWAY_ALREADY_JOINED = 'GIVEAWAY_ALREADY_JOINED',
  END_DATE_MISMATCH = 'END_DATE_MISMATCH',
  START_DATE_MISMATCH = 'START_DATE_MISMATCH',
  DISCORD_GUILD_NOT_JOINED = 'DISCORD_GUILD_NOT_JOINED',
  TWITTER_ACCOUNT_NOT_FOLLOWED = 'TWITTER_ACCOUNT_NOT_FOLLOWED',
  DISCORD_TOKEN_IS_INVALID = 'DISCORD_TOKEN_IS_INVALID',
  STEAM_ID_IS_INVALID = 'STEAM_ID_IS_INVALID',
  TWITTER_TOKEN_IS_INVALID = 'TWITTER_TOKEN_IS_INVALID',
  TWITCH_TOKEN_IS_INVALID = 'TWITCH_TOKEN_IS_INVALID',
  GIVEAWAY_TASK_NOT_FOUND = 'GIVEAWAY_TASK_NOT_FOUND',
  TASK_ACTION_INVALID = 'TASK_ACTION_INVALID',
  TASK_PERFORMED = 'TASK_PERFORMED',
  VOTE_ON_OWN_REVIEW = 'VOTE_ON_OWN_REVIEW',
  ALREADY_REVIEWED = 'ALREADY_REVIEWED',
  REVIEW_NOT_FOUND = 'REVIEW_NOT_FOUND',
  NO_REVIEWS_FOUND = 'NO_REVIEWS_FOUND',
  REPLY_NOT_FOUND = 'REPLY_NOT_FOUND',
  VOTE_ON_OWN_REPLY = 'VOTE_ON_OWN_REPLY',
  REPORT_ON_OWN_REVIEW = 'REPORT_ON_OWN_REVIEW',
  REPORT_ON_OWN_REPLY = 'REPORT_ON_OWN_REPLY',
  ALREADY_REPORTED = 'ALREADY_REPORTED',
}
