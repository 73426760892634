import { Avatar, Flex, Box, Heading, Text, Badge, Button, Link, useTheme } from '@chakra-ui/react';

import { TimelineItemProps } from './timeline.types';
import NextLink from 'next/link';
import { getPlaceholderUrl } from './timeline.helpers';
import { LazyImage } from '../lazy-image/lazy-image';
import { GlassBox } from '../glass-box/glass-box';
import { isExternalUrl } from '../../utils';

export const TimelineItem = ({
  title,
  description,
  icon,
  badges,
  discordId,
  image,
  links,
  lineClamp,
  url,
  element,
}: TimelineItemProps) => {
  const theme = useTheme();
  const firstLink = url || (!!links?.length ? links[0].href : '');

  return (
    <GlassBox ml="xl">
      <Flex>
        <Box flex={1}>
          <Box pr="lg">
            {discordId && (
              <Flex flexWrap="wrap" alignItems="center" gap={4} mb="lg">
                <Text color="gray.400" fontWeight="700" fontSize="xs">
                  Discord ID
                </Text>
                <Badge color="gray.400" bg="gray.700">
                  {discordId}
                </Badge>
              </Flex>
            )}
            {!!badges?.length && (
              <Flex flexWrap="wrap" alignItems="start" gap={2} mb="lg">
                {badges?.map((badge, index) => (
                  <Badge color="gray.400" bg="gray.700" key={`timeline-item-badge-${index}`}>
                    {badge}
                  </Badge>
                ))}
              </Flex>
            )}
            <Heading as="h4" size="h4" mb="sm" lineHeight="1.2">
              {title}
            </Heading>
            {element}
            {description && (
              <Text
                dangerouslySetInnerHTML={{ __html: description }}
                color="desiredDawn"
                noOfLines={lineClamp}
                sx={{
                  a: theme.components.Link.baseStyle,
                }}
              />
            )}
          </Box>
        </Box>
        {icon && (
          <Box>
            <NextLink href={firstLink} passHref legacyBehavior>
              <Link
                isExternal={isExternalUrl(firstLink)}
                pointerEvents={!firstLink ? 'none' : 'auto'}>
                <Avatar
                  size="sm"
                  src={`${icon.src}?w=80`}
                  name={icon.alt || ''}
                  _hover={{
                    cursor: 'pointer',
                  }}
                  backgroundColor="white"
                  sx={{
                    img: {
                      objectFit: 'contain',
                    },
                  }}
                />
              </Link>
            </NextLink>
          </Box>
        )}
      </Flex>
      {(image || !!links?.length) && (
        <Box display="grid" mx={-8} mb={-8}>
          {image && (
            <NextLink href={firstLink} passHref legacyBehavior>
              <Link
                isExternal={isExternalUrl(firstLink)}
                pointerEvents={!firstLink ? 'none' : 'auto'}
                gridArea="1/1"
                mt={{ base: 'lg', md: 'xl' }}
                position="relative"
                _after={{
                  content: '" "',
                  display: 'block',
                  position: 'absolute',
                  bottom: 0,
                  left: 0,
                  width: '100%',
                  height: '50%',
                  background: 'linear-gradient(0deg, rgba(28,27,32,0.9) 0%, rgba(28,27,32,0) 100%)',
                }}>
                {image && (
                  <LazyImage
                    alt=""
                    src={`${image}${url ? '' : '?w=1400'}`}
                    placeholderUrl={url ? image : getPlaceholderUrl(image)}
                    objectFit="cover"
                    w="100%"
                    height="52"
                  />
                )}
              </Link>
            </NextLink>
          )}

          {!!links?.length && (
            <Box gridArea="1/1" px="xl" pb="xl" pt={{ base: 'lg', md: '2xl' }} mt="auto">
              {links && (
                <Flex
                  flexDirection={{ base: 'column', md: 'row' }}
                  gap={{ base: 4, md: 5 }}
                  flexWrap="wrap">
                  {links.map(({ label, href, onClick, variant }, index) => (
                    <NextLink
                      href={href}
                      passHref
                      key={`timeline-item-button-${index}`}
                      legacyBehavior>
                      <Button
                        as={Link}
                        isExternal={isExternalUrl(href)}
                        variant={variant || (image ? 'primary' : 'secondary')}
                        width="min"
                        onClick={onClick}>
                        {label}
                      </Button>
                    </NextLink>
                  ))}
                </Flex>
              )}
            </Box>
          )}
        </Box>
      )}
    </GlassBox>
  );
};
