import { createClient, defaultExchanges } from '@urql/core';
import { createClient as createContentfulClient } from 'contentful';

const contentfulClient = createClient({
  // Makes sure the cache is also updated in URQL.
  // If we'd run cache only, Next would try to update the page, but still get old
  // content from the URQL cache.
  requestPolicy: 'cache-and-network',
  url: `https://graphql.contentful.com/content/v1/spaces/${process.env.CONTENTFUL_SPACE_ID}/environments/${process.env.CONTENTFUL_ENV_ID}`,
  exchanges: defaultExchanges,
  fetchOptions: () => ({
    headers: {
      Authorization: `Bearer ${process.env.CONTENTFUL_DELIVERY_ACCESS_TOKEN}`,
    },
  }),
});

const contenfulSearchClient = createContentfulClient({
  space: process.env.CONTENTFUL_SPACE_ID as string,
  environment: process.env.CONTENTFUL_ENV_ID,
  accessToken: process.env.CONTENTFUL_DELIVERY_ACCESS_TOKEN as string,
});

export { contentfulClient, contenfulSearchClient };
